import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"


const app = createApp(App);
app.use(router);
app.use(store);
app.config.productionTip = false;

document.title = 'Recipe Planner';

app.mount('#app');
